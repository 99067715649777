<template>
    <v-app>
        <transition name="fade-in-up">
            <div>
                <v-dialog v-model="push_notify_dialog" persistent max-width="600px">
                                    
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">Push Notify</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-container>
                                                <v-row>
                                                    <!-- <v-col cols="12" sm="12" md="12">
                                                        <v-text-field :disabled="true" v-model="push_notify_selected_device_id" label="Device ID (*)" required></v-text-field>
                                                    </v-col> -->
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-text-field v-model="push_heading" label="Tiêu đề (*)" required></v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" sm="12" md="12">
                                                        <v-text-field v-model="push_content" label="Nội dung (*)" required></v-text-field>
                                                    </v-col>
                                                    
                                                </v-row>
                                            </v-container>
                                            <small>(*) Trường bắt buộc</small>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="push_notify_dialog = false">Huỷ</v-btn>
                                            <v-btn color="blue darken-1" text @click="push_notify(push_notify_selected_device_id)">Push</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>

                
                
                <v-dialog v-model="voucher_dialog" persistent max-width="600px">
                                    
                                    <v-card>
                                        <v-card-title>
                                            <span class="headline">Mã khuyến mại</span>
                                        </v-card-title>
                                        <v-card-text>
                                            <!-- <span v-if="order.User_id" style="font-style:italic;">Danh sách mã khuyến mại của khách hàng: {{ order.User_id.Name }} ({{ order.User_id.Phone_num }})</span> -->
                                            <v-card
                                                v-if="!user_voucher.length"
                                                class="my-4"
                                                max-width="100%"
                                                tile
                                            >
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>Không có mã khuyến mại nào</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-card>
                                            <v-card
                                                v-else
                                                class="my-4"
                                                max-width="100%"
                                                tile
                                            >
                                                <v-list-item v-for="item in user_voucher" :key="item.Id" three-line>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ item.Voucher_code }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ item.Sale_of }} {{ item.Sale_max ? ` - ${item.Sale_max}` : '' }}</v-list-item-subtitle>
                                                        <v-list-item-subtitle>{{ format_date(item.Start_time_claim * 1000) }} - {{ format_date(item.End_time_claim * 1000) }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn @click="show_voucher(item)" small>
                                                            Xem
                                                        </v-btn>
                                                        <v-btn v-if="item.Is_apply" disabled small>
                                                            Đã sử dụng
                                                        </v-btn>
                                                        <v-btn v-else @click="confirm_apply_voucher(item)" small>
                                                            Gạch
                                                        </v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>

                                            </v-card>
                                        </v-card-text>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn color="blue darken-1" text @click="voucher_dialog = false">Đóng</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>






                <v-dialog v-model="voucher_detail_dialog" persistent max-width="400px">
                                        <v-card>
                                            <v-img
                                            v-if="selected_voucher.Banner"
                                            :src="selected_voucher.Banner"
                                            height="200px"
                                            ></v-img>
                                            <v-card-title>
                                                <span class="headline">{{ selected_voucher ? selected_voucher.Voucher_code : '' }}</span>
                                            </v-card-title>
                                            <v-card-subtitle>
                                                {{ selected_voucher ? selected_voucher.Name : '' }}
                                            </v-card-subtitle>
                                            <v-card-text>
                                                <span><strong>Thời gian chương trình:</strong> {{ format_date(selected_voucher.Start_time_active * 1000) }} - {{ format_date(selected_voucher.End_time_active * 1000) }}</span><br>
                                                <span><strong>Thời gian nhận quà:</strong> {{ format_date(selected_voucher.Start_time_claim * 1000) }} - {{ format_date(selected_voucher.End_time_claim * 1000) }}</span><br>
                                                <span><strong>Giảm giá:</strong> {{ selected_voucher.Sale_of }}</span><br>
                                                <span><strong>Tối đa:</strong> {{ selected_voucher.Sale_max }}</span><br>
                                            </v-card-text>
                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn color="blue darken-1" text @click="voucher_detail_dialog = false">Đóng</v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>







                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                        :headers="headers"
                        :items="users"
                        :loading="loading"
                        class="elevation-1"

                        @pagination="update_page"
                        :server-items-length="total_users"
                        :items-per-page="items_per_page"
                        :page="page"
                        >
                            <template v-slot:top>
                                <div class="d-flex align-items-center justify-content-between">
                                    
                                    
                                    <div class="col-md-4">
                                        <v-dialog v-model="dialog" persistent max-width="600px">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn color="primary" v-bind="attrs" v-on="on">
                                                            <v-icon small class="mr-2">mdi-plus</v-icon>Thêm khách hàng
                                                        </v-btn>
                                                    </template>
                                                    <v-card>
                                                        <v-card-title>
                                                            <span class="headline">Thêm khách hàng</span>
                                                        </v-card-title>
                                                        <v-card-text>
                                                            <v-container>
                                                                <v-row>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_name" label="Tên KH(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_phone" label="SĐT(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_addr" label="Địa chỉ(*)" required></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_username" label="Tên đăng nhập"></v-text-field>
                                                                    </v-col>
                                                                    <v-col cols="12" sm="12" md="12">
                                                                        <v-text-field v-model="new_user_password" label="Mật khẩu"></v-text-field>
                                                                    </v-col>
                                                                </v-row>
                                                            </v-container>
                                                            <small>(*)Trường bắt buộc</small>
                                                        </v-card-text>
                                                        <v-card-actions>
                                                            <v-spacer></v-spacer>
                                                            <v-btn color="blue darken-1" text @click="dialog = false">Huỷ</v-btn>
                                                            <v-btn color="blue darken-1" text @click="create_user()">Thêm</v-btn>
                                                        </v-card-actions>
                                                    </v-card>
                                                </v-dialog>
                                    </div>
                                    <div class="col-md-4">
                                        <v-text-field
                                        append-icon="mdi-magnify"
                                        single-line
                                        hide-details
                                        v-model="query"
                                        label="Search"
                                        class="mx-4"
                                        ></v-text-field>

                                        <div class="col-md-12">
                                            <!-- <v-switch v-model="has_device_id" :label="has_device_id ? 'Có DeviceID' : 'Tất cả'"></v-switch> -->
                                            <!-- <v-checkbox
                                                v-model="has_device_id"
                                                label="Lọc user có DeviceID"
                                            ></v-checkbox> -->
                                            <b-form-checkbox
                                                id="checkbox-1"
                                                v-model="has_device_id"
                                                name="checkbox-1"
                                                :value="true"
                                                :unchecked-value="false"
                                            >
                                                Lọc user có DeviceID
                                            </b-form-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:item.Time_reg="{ item }">
                                {{ new Date(item.Time_reg * 1000).toLocaleString("vi-VN") }}
                            </template>
                            <template v-slot:item.push_notify="{ item }">
                                <v-btn color="info" v-if="item.Device_id" small @click="show_push_notify(item)">
                                    <v-icon class="mr-1" small>mdi-cellphone-message</v-icon> Push
                                </v-btn>
                            </template>
                            <template v-slot:item.get_pin="{ item }">
                                <v-btn color="info" small @click="get_pin(item.Id)">
                                    <v-icon class="mr-1" small>mdi-lock-reset</v-icon> PIN
                                </v-btn>
                            </template>
                            <template v-slot:item.delete="{ item }">
                                <v-btn color="error" small @click="confirm_delete(item)">
                                    <v-icon class="mr-1" small>mdi-delete</v-icon> Xoá
                                </v-btn>
                            </template>
                            <template v-slot:item.voucher="{ item }">
                                <v-btn color="info" small @click="get_user_voucher(item.Id)">
                                    <v-icon class="mr-1" small>mdi-gift</v-icon> Xem
                                </v-btn>
                            </template>
                            <template v-slot:item.Device_id="{ item }">
                                {{ item.Device_id ? (item.Device_id.split(',').length > 1 ? item.Device_id.split(',').join("\n \n") : item.Device_id) : item.Device_id }}
                            </template>

                        </v-data-table>
                    </div>
                </div>
            </div>
        </transition>
    </v-app>
</template>


<style lang="scss">
@import "~vuetify/dist/vuetify.css";

.theme--light.v-application {
  background: transparent !important;
}
.v-application code {
  box-shadow: none !important;
}

td {
    white-space:pre;
}

.coupon-area {
    padding: 12px;
}
.v-list-item:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .05);
}
.v-list-item__title {
    font-weight: 600 !important;
}
.v-list-item__action--stack {
    display: flex;
    flex-direction: row !important;
    align-items: center !important;
    gap: 10px;
    margin: auto !important;
}
.v-dialog > .v-card > .v-card__subtitle {
    padding: 5px 24px 20px;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Swal from "sweetalert2";

export default {
    metaInfo: {
        title: 'Tài khoản'
    },
    data() {
        return {
            loading: false,
            options: {},
            query: this.$route.query.query || "",
            dateRange: {},
            start: this.$route.query.start || "",
            end: this.$route.query.end || "",
            has_device_id: false,
            headers: [
                { text: "ID", align: 'start', sortable: false, value: 'Id'},
                { text: "FB ID", value: 'Fb_id', sortable: false },
                { text: "SĐT", value: 'Phone_num', sortable: false },
                { text: "Tên đăng nhập", value: 'User_name', sortable: false },
                { text: "Tên", value: 'Name', sortable: false },
                { text: "Địa chỉ 1", value: 'Adr1', sortable: false },
                // { text: "Địa chỉ 2", value: 'Adr2', sortable: false },
                { text: "Device ID", value: 'Device_id', sortable: false },
                { text: "Tuổi", value: 'Old', sortable: false },
                { text: "Ngày sinh", value: 'Birthday', sortable: false },
                { text: "Giới tính", value: 'Gender', sortable: false },
                { text: "Thời gian đăng ký", value: 'Time_reg', sortable: false },
                { text: "Tên thiết bị", value: 'Device_name', sortable: false },
                { text: "Loại", value: 'User_type', sortable: false },
                { text: "Push Notify", value: 'push_notify', sortable: false },
                { text: "Lấy mã PIN", value: 'get_pin', sortable: false },
                { text: "Voucher", value: 'voucher', sortable: false },
                { text: "", value: 'delete', sortable: false },
            ],
            push_notify_dialog: false,
            push_heading: "",
            push_content: "",
            push_notify_selected_device_id: null,

            // for modal
            dialog: false,
            new_user_phone: "",
            new_user_name: "",
            new_user_addr: "",
            new_user_username: "",
            new_user_password: "",

            // for voucher modal
            selected_voucher: {},
            voucher_dialog: false,
            voucher_detail_dialog: false,
            voucher_user_id: null,

        };
    },
    watch: {
        options: {
            handler () {
                this.get_users();
            },
            deep: true,
        },
        query: {
            handler() {
                this.search_users();
            },
            deep: true
        },
        has_device_id: {
            handler() {
                this.get_users();
            },
            deep: true
        }
    },
    computed: {
        users() {
            return this.$store.getters['users/get_users'];
        },
        total_users() {
            return this.$store.getters['users/get_total_users'];
        },
        page() {
            return this.$route.query.page ? parseInt(this.$route.query.page) : 1;
        },
        items_per_page() {
            return this.$route.query.limit ? parseInt(this.$route.query.limit) : 15;
        },
        user_voucher() {
            return this.$store.getters['promotions/get_user_voucher'];
        }
    },
    components: {
    },
    methods: {
        get_users() {
            var self    = this;
            self.loading = true;
            const page  = this.$route.query.page || 1;
            const limit = this.$route.query.limit || 15;
            const q = this.query || "";
            const start = this.start || "";
            const end = this.end || "";
            const has_device_id = this.has_device_id || false;

            var payload = {
                pageSize: limit,
                pageIndex: page,
            };

            if(q) {
                payload.nameQuery = q;
            }
            if(start && end) {
                payload.startTimeRegisted = start;
                payload.endTimeRegisted = end;
            }
            if(has_device_id) {
                payload.hadDeviceID = has_device_id;
            }

            this.$router.push({
                name: "users-index",
                query: { page, limit, q, start, end, has_device_id }
            }).catch(() => { });

            this.$store.dispatch('users/get_all', payload).then(() => {
                self.loading = false;
            }).catch(() => {
                self.loading = false;
            });
        },
        search_users() {
            const page  = 1;
            const limit = this.$route.query.limit || 15;
            const start = this.start;
            const end = this.end;

            this.$router.push({
                name: "users-index",
                query: { page, limit, q: this.query, start, end }
            }).catch(() => { });
            this.get_users();
        },
        edit_item(item) {
            console.log("edit item:", item);
            // this.$router.push(`categories/update/${item.id}`).catch(() => { });
        },
        update_page(pagination) {
            const page  = pagination.page;
            const limit = pagination.itemsPerPage;
            const q = this.query;
            const start = this.start;
            const end = this.end;

            this.$router.push({
                name: "users-index",
                query: { page, limit, q, start, end }
            }).catch(() => { });
            this.get_users();
        },
        select_date() {
            if(!this.dateRange) return;
            if(!this.dateRange.dateRange) return;
            if(!this.dateRange.dateRange.start || !this.dateRange.dateRange.end) return;

            var start_str = this.dateRange.dateRange.start;
            var end_str = this.dateRange.dateRange.end;

            var start_date = start_str.split("/")[0];
            var start_month = start_str.split("/")[1];
            var start_year = start_str.split("/")[2];
            var end_date = end_str.split("/")[0];
            var end_month = end_str.split("/")[1];
            var end_year = end_str.split("/")[2];

            var start = new Date(`${start_month}/${start_date}/${start_year}`).getTime() / 1000;
            var end = new Date(`${end_month}/${end_date}/${end_year}`).getTime() / 1000;

            this.start = start;
            this.end = end;

            const page  = 1;
            const limit = this.$route.query.limit || 15;
            const q = this.query;

            this.$router.push({
                name: "users-index",
                query: { page, limit, q, start, end }
            }).catch(() => { });
            this.get_users();
        },
        show_push_notify(item) {
            var selected_device_id = [];
            if(item.Device_id) {
                selected_device_id = item.Device_id.split(',');
                // if(item.Device_id.split(',').length > 1) {
                //     selected_device_id = item.Device_id.split(',')[0];
                // } else {
                //     selected_device_id = item.Device_id;
                // }
            }
            this.push_notify_selected_device_id = selected_device_id;
            this.push_notify_dialog = true;
        },
        push_notify(device_id) {
            console.log("device_id", device_id)
            var payload = {
                Content: this.push_content,
                Headings: this.push_heading,
                Player_ids: device_id ? device_id : [],
                toast: this.$root.$bvToast,
            }
            this.$store.dispatch('users/push_notify', payload).then(() => {
                this.push_notify_dialog = false;
            }).catch(e => {
                console.log(e);
            });
        },
        get_pin(id) {
            this.$store.dispatch('users/get_pin', {id}).then(res => {
                if(res.data.Data) {
                    Swal.fire({
                        text: `PIN: ${res.data.Data.Pincode}`,
                        icon: "success",
                        heightAuto: false
                    });
                } else {
                    Swal.fire({
                        text: `Có lỗi xảy ra`,
                        icon: "error",
                        heightAuto: false
                    });
                }
                
            }).catch(e => {
                console.log(e);
                Swal.fire({
                    text: `Có lỗi xảy ra`,
                    icon: "error",
                    heightAuto: false
                });
            });
        },
        create_user() {
            this.loading = true;
            var self = this;
            var payload = {
                Phone_num: this.new_user_phone,
                Name: this.new_user_name,
                Adr1: this.new_user_addr,
                toast: this.$root.$bvToast,
            }
            if(this.new_user_username) {
                payload.User_name = this.new_user_username;
            }
            if(this.new_user_password) {
                payload.Pass = this.new_user_password;
            }
            this.$store.dispatch('users/create_user', payload).then(() => {
                self.get_users();
                self.reset_add_user_form();
                self.dialog = false;
                self.loading = false;
            }).catch(() => {
                self.get_users();
                self.reset_add_user_form();
                self.dialog = false;
                self.loading = false;
            });
        },
        reset_add_user_form() {
            this.new_user_phone = '';
            this.new_user_name = '';
            this.new_user_addr = '';
            this.new_user_username = '';
            this.new_user_password = '';
        },
        get_user_voucher(user_id) {
            this.voucher_user_id = user_id;
            this.voucher_dialog = true;
            this.$store.dispatch('promotions/get_user_voucher', {id: user_id});
        },
        format_date(date) {
            return new Date(date).toLocaleString('vi-VN', {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
        },
        confirm_delete(item) {
            var self = this;
            Swal.fire({
                title: `Xoá tài khoản: ${item.Name || item.Phone_num || item.Id}?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Có',
                cancelButtonText: 'Không'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.delete_item(item.Id);
                }
            })
        },
        delete_item(user_id) {
            this.$store.dispatch('users/delete_user', { id: user_id, toast: this.$root.$bvToast }).then(() => {
                this.loading = false;
                this.get_users();
            }).catch(() => {
                this.loading = false;
            });
        },
        show_voucher(voucher) {
            this.voucher_detail_dialog = true;
            this.selected_voucher = voucher;
        },
        confirm_apply_voucher(item) {
            if(!item) return;
            var self = this;
            Swal.fire({
                title: `Sử dụng voucher ${item.Voucher_code} ?`,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Sử dụng',
                cancelButtonText: 'Huỷ'
                }).then((result) => {
                if (result.isConfirmed) {
                    self.apply_voucher(item.Id);
                }
            })
        },
        async apply_voucher(voucher_id) {
            if(!voucher_id) return;
            if(!this.voucher_user_id) return;

            const payload = { user_id: this.voucher_user_id, promotion_id: voucher_id, toast: this.$root.$bvToast, };
            await this.$store.dispatch('promotions/apply_promotion', payload);
            this.get_user_voucher(this.voucher_user_id);
        }
    },
    mounted() {
        this.$store.dispatch(SET_BREADCRUMB, [ { title: "Tài khoản", route: "index" }, { title: "Tất cả" } ]);
        this.get_users();
    }
};
</script>
